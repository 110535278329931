import React, {useState} from 'react'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { API_URL } from '../constant';
import { 
  Grid
 } from '@material-ui/core';
import PropTypes from 'prop-types';
const axios = require('axios');


function UploadFile({getUploadResponse,enableLoading }) {
  const [state, setState] = useState({
    fileStatus: 'pending',
    url :'',
    response:''
  })

  function getSigned(name) {
        return axios.get(`${API_URL}/upload/image?file_name=${name}`).then(response => {
          return response.data
    })
  }

  const addImage = async ( url ) => {
    const response = await axios.post(API_URL+'/image/screen', {
      url :url
    });
    //console.log(props);
    getUploadResponse(response.data);
  }
  
  const getUploadParams = async ({ file }) => {
    enableLoading();
    const signed = await getSigned(file.name);
    let uploadURL = signed["upload_url"];
      return { method: 'put',  body: file, url: uploadURL, headers: {"Access-Control-Allow-Origin": "*"} }
  }

  const handleChangeStatus = async ({file},status) => {
    if(status === "done"){
       const result = await addImage('https://s3.us-east-2.amazonaws.com/images.demo/'+file.name);
       setState({...state, fileStatus:status,  response : result});
    }
    else{
        setState({...state, fileStatus:status});
    }
  }

  return(
      <Grid
      container
      spacing={3}
    >
      
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        accept="image/jpeg, image/png"
        inputContent={(files, extra) => (extra.reject ? 'Image jpg/png ,files only' : 'Drag and drop an image')}
        styles={{
          dropzone: { width: 400, height: 200 },
          dropzoneActive: { borderColor: 'green' },
        }}
        />
    </Grid>
  )
}


UploadFile.propTypes = {
  className: PropTypes.string,
};
export default UploadFile;
