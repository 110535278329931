import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import {
    Button,
    TextField,
    Box,
    Grid
  } from '@material-ui/core';
import { API_URL } from '../constant';

const axios = require('axios');


class UrlUpload extends React.Component {
    constructor() {
      super()
      this.state = {  url:'' }
      this.handleClick = this.handleClick.bind(this)
    }
  
    handleClick = async(e) => {
        if(this.state.url){
            this.props.enableLoading();
            const url = this.state.url;
            const result = await axios.post(API_URL+'/image/screen', {
              url :url
            });
            this.props.getUrlResponse(result.data);
        }   
    }
  
    render() {
        return (
          <Grid >
            <Box mt={3} 
                mb={3} 
                ml={3} 
                display="flex" 
                justifyContent="center"> 
              <TextField     
                      label="enter url"
                      name="ImageUrl"
                      variant="outlined"
                      value={this.state.url}
                      id="url"
                      onChange={(e) => {
                          this.setState({url : e.target.value})
                      }}
              />
              <Button color="secondary"
                      variant="contained" onClick={(e) =>  this.handleClick(e)}>submit</Button>
              </Box>
            </Grid>
          )
    }
  }
  export default UrlUpload
