import React, {  Component} from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  TableContainer,
  Button,
  Dialog
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';


class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
          barcode : "",
          open:false,
          selectedBarcode:'',
          imageLoaded:[],
          errorOnce:[],
        };
      }

  getImage =  (name) =>{
    if(name){
      return "https://images.demo.s3.us-east-2.amazonaws.com/"+name;
    }
  }

// set if image is loaded
handleImageLoaded = (event,index) => {
    let imageArray = this.state.imageLoaded;
    imageArray.splice(index,0,true);
    this.setState({
      imageLoaded : imageArray,
    });
  }

  // trying to get image with either lower/upper case
  handleImageErrored = (event,barcode,skip) => {
    if(skip || !this.state.errorOnce.hasOwnProperty(barcode)){
      if(!skip){
        let errorArray = this.state.errorOnce;
        errorArray[barcode] = true;
        this.setState({
          errorOnce: errorArray
        });
      }
      if(barcode === barcode.toUpperCase()){
        event.target.setAttribute("src",this.getImage(barcode.toLowerCase()))
      }else {
        event.target.setAttribute("src",this.getImage(barcode.toUpperCase()))

      }
    }
  }
  
  // show image dialog
  showDialog = (index) =>{
    console.log(this.state.imageLoaded);
    if(this.state.imageLoaded.hasOwnProperty(index)){
      return true;
    }
    return false;
  }

   // handle dialog close
   handleClose = () => {
    this.setState({
      open:false
    })
  };

  render(){
  const {
      jobs
  } = this.props;
  const {
    selectedBarcode
  } = this.state; 

  return (
    <div>
      <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >UUID</TableCell>
            <TableCell >Thumbnail</TableCell>
            <TableCell >Status</TableCell>
            <TableCell >BestGuess</TableCell>
            <TableCell >Keywords</TableCell>
            <TableCell >KeywordScore</TableCell>
            <TableCell >WebMatches</TableCell>
            <TableCell >WebmatchScore</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map((row,i) => (
            <TableRow key={row.uuid}>
              <TableCell component="th" scope="row">
                {row.uuid}
              </TableCell>
              <TableCell>
              <Button 
                   >
                  <Avatar 
                    variant='square'
                    alt={row.imageUrl} 
                  >
                        <img src={row.imageUrl}
                        alt={row.imageUrl}/>
                  </Avatar>
                  </Button>
            </TableCell>
            <TableCell>
                {row.status}
            </TableCell>
            <TableCell>{row.bestGuess}</TableCell>
            <TableCell>{row.keywords}</TableCell>
            <TableCell>{row.keywordScore}</TableCell>
            <TableCell>{row.webMatches}</TableCell>
            <TableCell>{row.webmatchScore}</TableCell>
           </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Dialog
        style={{width: '600px', marginLeft: '30%', backgroundColor: 'transparent',overflow: 'hidden'}}
        onClose={this.handleClose}
        open={this.state.open}
        >
         { 
           /*eslint-disable */
         }
          <img src={this.getImage(selectedBarcode)} style={{width: '430px', height:'auto',overflow: 'hidden'}} 
           onError={(event) => {
            this.handleImageErrored(event,selectedBarcode,true);                     
          }}/>
          { 
            /*eslint-enable */
          }
    </Dialog>
    </div>
  )
  }
}

Results.propTypes = {
  jobs:PropTypes.array
};

export default Results;