import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Link } from "react-router-dom";
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';

const useStyles  = theme => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
    },
  });

class Header extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <AppBar position="fixed">
                <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                <Button  color="inherit" component={Link} to="/">
                Image Screener
                </Button>
               {/*  <Button color="inherit" component={Link} to="/list">Results</Button>*/}  
                </Toolbar>             
                 </AppBar>
            </div>  
        )
    }
}

export default withStyles(useStyles)(Header);
