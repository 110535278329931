import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { 
    Grid, 
    makeStyles, 
    Box
} from '@material-ui/core';
import ResultList from './ResultList';
import Header from '../Header';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop:40,
  }
}));


function History({ className, ...rest }) {
  const classes = useStyles();
  return (
    <div>
       <Header />
       <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Box>
         <ResultList className={className}/>
        </Box>
      </Grid>
    </Grid>
    </div>
  );
}

History.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default History;
