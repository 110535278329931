import React from 'react';
import Header from './Header';
import UploadFile from './UploadFile';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography
  } from '@material-ui/core';
import UrlUpload from './UrlUpload';
import JSONPretty from 'react-json-pretty';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles  = theme => ({
    root: {
      flexGrow: 1,
    },
    wrapper: {
        paddingTop:40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
   
  });

class ImageUplaoder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadResponse:'',
            urlResponse:'',
            loading:false,
            error:''
        }
    }

    enableLoading = () => {
        this.setState({
            loading:true,
            error:''
        })
    }

    disableLoading = (errorText) => {
        this.setState({
            loading:false,
            error: errorText
        })
    }

    getUrlResponse = (text) => {
        this.setState({urlResponse: text,  loading:false});
    }
    getUploadResponse = (text) => {
        this.setState({uploadResponse: text, loading:false});
    }
    render() {
        const { classes } = this.props;

    return (
        <Grid>
           {this.state.loading &&  <Backdrop  className={classes.backdrop} open={this.state.loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>}  
            <Header/>
            <Grid
            className={classes.wrapper}
                item
                xs={12}
            >
            <Box  mt={3} ml={3} mr={3}>
            <Card
            >
                    <CardHeader />
                    <CardContent>
                       
                        <UploadFile getUploadResponse={this.getUploadResponse} enableLoading={this.enableLoading}/>
                        {this.state.uploadResponse && <Box 
                        mt={3}
                        mb={3}
                        ml={3}
                        border={1}
                        borderColor="blue"
                        borderRadius={10}
                        justifyContent="center"
                        >
                             <Typography
                                variant="h5"
                                color="textSecondary"
                                >
                             Result :
                            </Typography>
                            <JSONPretty  style={{ wordWrap: "break-word", overflow:"auto" }} data={this.state.uploadResponse}></JSONPretty>
                        </Box>}
                        <Box 
                        mt={3}
                        mb={3}
                        ml={3}
                        display="flex" 
                        justifyContent="center">
                            <Typography>Or enter image url</Typography>
                        </Box>
                        <UrlUpload 
                        getUrlResponse={this.getUrlResponse} 
                        enableLoading={this.enableLoading}
                        disableLoading={this.disableLoading}/>
                        {this.state.urlResponse && <Box 
                        mt={4}
                        mb={4}
                        ml={4}
                        justifyContent="center"
                        border={1}
                        borderColor="blue"
                        borderRadius={10}>
                             <Typography
                                variant="h5"
                                color="textSecondary"
                                noWrap
                                >
                             Result :
                            </Typography>
                            <JSONPretty style={{ wordWrap: "break-word", overflow:"auto" }} data={this.state.urlResponse}></JSONPretty>
                           
                        </Box> 
                        }
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Grid>
       
        )
    }
}

export default withStyles(useStyles)(ImageUplaoder)
