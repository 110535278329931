import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ImageUploader from '../ImageUploader'

import Results from '../list/index'

function App() {

    return (
        <Router>
          <Switch>
            <Route path="/" exact component={ImageUploader} />
            <Route path="/list"  component={Results} />
          </Switch>
        </Router>
  );
}
export default App;