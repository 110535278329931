import React, {  Component} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { API_URL } from '../../constant/index';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import Results from './Results';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import readingTime from "reading-time";

class ResultList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        jobs: [],
        snackbarError: {
            autoHideDuration: 0,
            message: "",
            open: false
        },
        snackbarSuccess: {
            autoHideDuration: 0,
            message: "",
            open: false
        },
    };
    this.updateList();
   }
 
  async componentDidMount() {
    //this.timer = setInterval(() =>  this.updateList(), 1000*60*5);
    //this.timer = setInterval(() =>  this.alertList(), 1000*60);
  }
  
  componentWillUnmount() {
   // clearInterval(this.timer);
   // this.timer = null;
  }

  updateList = async () =>{
    const list = await this.fetchAllImages();
    this.setState({
        jobs: list,
    });
  } 

  
  fetchAllImages = async () => { 
    try {
        //const error = [],completed =[];
       return await fetch(API_URL+`/image/list`)
      .then(function(response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }    
        return response.json();
      })
      .catch(function(error) {
        console.log('Looks like there was a problem: \n', error);
      });
    } catch(err) {
      return err;
    }
  }


  openErrorSnackbar = message => {
    this.setState({
        snackbarError: {
        autoHideDuration: readingTime(message).time * 2,
        message,
        open: true,
        status:'error',
        position:{ vertical: 'bottom', horizontal: 'right' }
      }
    });
  };

  closeErrorSnackbar = (clearMessage = false) => {
    const { snackbarError } = this.state;

    this.setState({
      snackbarError: {
        message: clearMessage ? "" : snackbarError.message,
        open: false
      }
    });
  };
  openSuccessSnackbar = message => {
    this.setState({
      snackbarSuccess: {
        autoHideDuration: readingTime(message).time * 2,
        message,
        open: true,
        status:'success',
        position :{ vertical: 'bottom', horizontal: 'left' }
      }
    });
  };

  closeSuccessSnackbar = (clearMessage = false) => {
    const { snackbarSuccess } = this.state;

    this.setState({
        snackbarSuccess: {
        message: clearMessage ? "" : snackbarSuccess.message,
        open: false
      }
    });
  };

  render(){
      const {className} = this.props;
      const {jobs,snackbarError,snackbarSuccess} = this.state;
      const styles = {
       snakbar: {
          maxWidth: "400px",
          height: "100%",
         }
      };
      return(
        <Box mt={3} marginLeft={3}>
            <Card
            className={clsx(className)}>
                <CardHeader title="Results" />
                <Divider />
                <CardContent>
                  <Results jobs={jobs}/>
                </CardContent>
            </Card>
            <Snackbar
              style = {styles.snakbar}
              autoHideDuration={snackbarError.autoHideDuration}
              open={snackbarError.open}
              anchorOrigin={snackbarError.position}
            >
            <MuiAlert elevation={6} variant="filled" onClose={this.closeErrorSnackbar} severity={snackbarError.status}>
            {snackbarError.message}
            </MuiAlert>
            </Snackbar>
            <Snackbar
              style = {styles.snakbar}
              autoHideDuration={snackbarSuccess.autoHideDuration}
              open={snackbarSuccess.open}
              anchorOrigin={snackbarSuccess.position}
            >
            <MuiAlert elevation={6} variant="filled" onClose={this.closeSuccessSnackbar} severity={snackbarSuccess.status}>
            {snackbarSuccess.message}
            </MuiAlert>
            </Snackbar>
           
      </Box>
      );
  }
}
ResultList.propTypes = {
    className: PropTypes.string,
  };
  
export default ResultList;